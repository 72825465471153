// import * as uuidLib from 'uuid';

class Section {
  id = '';
  media = '';
  media_desc = '';
  media_type = '';
  title = '';
  content = '';
}

export default Section;
