// import * as uuidLib from 'uuid';

class Newsroom {
  uuid = '';
  title = '';
  content = '';
  media = '';
  media_desc = '';
  media_type = '';
  sections = [
    {
      id: '',
      title: '',
      content: '',
      media: '',
      media_desc: '',
      media_type: ''
    }
  ];
  is_publish = false;
}

export default Newsroom;
